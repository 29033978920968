<template>
	<div style="display: flex;padding: 24rem">
		<div style="width: 50%;">
			<div style="display:flex;align-items: center;margin: 24rem 0">
				<div>教室宿舍更新</div>
				<el-select v-model="value" placeholder="请选择校区" size="mini" style="margin: 0 24rem">
					<el-option
						v-for="(item,index) of options"
						:key="options[index]"
						:label="options[index]"
						:value="index"
					>
					</el-option>
				</el-select>
				<div>选择类型</div>
				<el-select v-model="type" placeholder="请选择类型" size="mini" style="margin: 0 24rem">
					<el-option v-for="i in [{label: '教室',value:1},{label: '宿舍',value: 2}]" :label="i.label" :value="i.value"></el-option>
				</el-select>
			</div>

			<span v-if="!value || !type" style="color: red;font-size: 14rem">*上传前请先选择校区和类型</span>
			<div style="display: flex;margin-top: 48rem">
				<el-button size="small" style="height: 32rem;margin-right: 24rem" @click="onDownload(1)">下载教室模板</el-button>
				<el-button size="small" style="height: 32rem;margin-right: 24rem" @click="onDownload(2)">下载宿舍模板</el-button>
				<div>
					<el-upload
						:action="$_axios2.defaults.baseURL + '/api/tool/import/room-dormitory-update'"
						:before-upload="beforeUpload"
						:data="{
							school_id: value,
							type
						}"
						:disabled="value == type === ''"
						:headers="{ authorization: 'Bearer ' + $store.getters.token }"
						:on-success="onSuccess"
						:show-file-list="false"
						accept=".xlsx,.xls"
						class="upload-demo"
					>
						<el-button size="small" type="primary">点击上传</el-button>
					</el-upload>
				</div>
			</div>
			<div v-for="i in message" style="margin-top: 24rem;font-size: 14rem;color: red">{{ i }}</div>
		</div>
	</div>
</template>

<script>
export default {
	_config:{"route":{"path":"list","meta":{"title":"列表"}}},
  data() {
		return {
			data: {
				admin_menu: [],
				teaching_menu: [],
			},
			defaultProps: {
				children: 'child',
				label: 'name',
			},
			value: '',
			type: '',
			options: [],
			message: [],
		};
	},

	methods: {
		onSuccess(response) {
			if (response.status === 0) {
				this.$message.success(response.msg);
			} else {
				this.message = response.data;
				this.$message.warning(response.msg);
			}
		},

		beforeUpload(file) {
			const testmsg = file.name.substring(file.name.lastIndexOf('.') + 1);
			const extension = testmsg === 'xls';
			const extension2 = testmsg === 'xlsx';
			if (!extension && !extension2) {
				this.$message({
					message: '上传文件只能是xls、xlsx格式!',
					type: 'warning',
				});
			}
			return extension || extension2;
		},

		//	下载模板
		onDownload(val) {
			if (val === 1) location.href = process.env.VUE_APP_URL_BASE + '/template/room_import.xlsx';
			else location.href = process.env.VUE_APP_URL_BASE + '/template/dormitory_import.xlsx';
		},
	},
	created() {
		this.options = JSON.parse(sessionStorage.getItem('USER_INFO')).school_arr;
	},
};
</script>

<style lang="scss" scoped>
.div-btn {
	margin-bottom: 24rem;
}
</style>
